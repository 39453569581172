<template>
  <div class="page">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      :finishedText="finishedText"
      ref="list"
    >
      <div
        class="classItem"
        v-for="(item, index) in listData"
        :key="index"
        @click="goPath('myAppointmentDetail', item)"
      >
        <div class="classItem-top">
          <div class="name">{{ item.className }}</div>
          <div class="time">{{ item.time }}</div>
        </div>
        <div class="place">地点：{{ item.classAddress }}</div>
        <div class="time">预约日期：{{ item.appointmentDate }}</div>
        <div class="btn" @click.stop="cancel(item)">取消预约</div>
      </div>
    </v-list>
  </div>
</template>

<script>
import { myAppointmentList, cancelAppointment } from "./api.js";
export default {
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 20,
      },
      timer: null,
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
  },
  methods: {
    goPath(name, item) {
      this.$router.push({
        name: name,
        query: {
          classId: item.classId,
        },
      });
    },
    cancel(item) {
      let params = {
        classId: item.classId,
        userId: this.userId,
        appointmentDate: item.appointmentDate,
      };
      this.$axios.post(`${cancelAppointment}`, params).then((res) => {
        if (res.code == 200) {
          this.$toast(res.msg);
          this.timer = setTimeout(() => {
            this.listData = [];
            this.requestData.curPage = 1;
            this.finished = false;
            this.onLoad();
          }, 1000);
        }
      });
    },
    onLoad() {
      this.getList();
    },
    getList() {
      let params = {
        userId: this.userId,
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
      };
      this.$axios
        .get(`${myAppointmentList}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.listData.push(item);
            });
          }
        });
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 30px 30px 90px;
  .classItem {
    height: 252px;
    background: #f3f8fc;
    border-radius: 8px;
    margin-bottom: 20px;
    box-sizing: border-box;
    padding: 0 34px;
    position: relative;
    .btn {
      width: 164px;
      height: 62px;
      background: #93ccf1;
      border-radius: 10px;
      line-height: 62px;
      text-align: center;
      font-size: 30px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      position: absolute;
      bottom: 28px;
      right: 28px;
    }
    .place {
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      padding-bottom: 20px;
      padding-top: 18px;
    }
    .time {
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
    }
    .classItem-top {
      height: 96px;
      box-sizing: border-box;
      border-bottom: 2px solid #e7e7e7;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>